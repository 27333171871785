import { NgModule } from '@angular/core';
import { FooterComponent } from '../../layouts/panelLayout/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    RouterModule,
    TranslateModule.forChild({}),
  ],
  exports: [
    TranslateModule,
    FooterComponent,
    RouterModule,
  ]
})
export class AppSharedModule { }
