<footer>
  <div class="help">
    <div>
      <img src="/assets/images/logo_white.svg" alt="" class="logo">
    </div>
    <ul>
      <li><a [routerLink]="['/contact']">{{"home.Help & Contact"|translate}} </a></li>
      <li><a [routerLink]="['/faq']"> {{"home.Frequently Asked Questions"|translate}}</a></li>
      <li><a [routerLink]="['/terms']"> {{"home.Terms & Conditions"|translate}}</a></li>
      <li><a [routerLink]="['/privacy-policy']"> {{"home.Privacy Policy"|translate}}</a></li>
    </ul>

    <!-- <div class="btn-container" [hidden]="authService.currentUserValue">
      <button mat-button class="btn" (click)="OnRegisterAsCompany()">{{"homepage.Sign up as a company"|translate}}</button>
      <button mat-button class="btn" (click)="OnRegisterAsVendor()">{{"homepage.Join us as a vendor"|translate}}</button>
    </div> -->


    <div class="downloadApp">
      <p class="title">
        {{"home.Download our app"|translate}}
      </p>
      <a routerLink="/soon"><img src="/assets/images/google_play.png" alt="image"></a>
      <a routerLink="/soon"><img src="/assets/images/appStore.png" alt="image"></a>
    </div>
  </div>


  <div class="copy_right row">

    <div class="col-md-6 col-12 social_links">
      {{"home.Follow Us"|translate}}
        <!-- LinkedIn link -->
        <a href="https://www.linkedin.com/company/offerxx" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_linkedin" d="M20,6H16.016V-.75a1.859,1.859,0,0,0-.727-1.359,2.329,2.329,0,0,0-1.523-.562,1.547,1.547,0,0,0-1.289.563A2.355,2.355,0,0,0,11.984-.75V6H8V-6h3.984v2.016A3.7,3.7,0,0,1,13.578-5.3,4.671,4.671,0,0,1,15.5-5.766,4.442,4.442,0,0,1,18.688-4.43,4.513,4.513,0,0,1,20-1.266ZM5.984,6H2V-6H5.984ZM4.016-12a1.869,1.869,0,0,1,1.406.586,1.987,1.987,0,0,1,.563,1.43,1.908,1.908,0,0,1-.562,1.406,1.908,1.908,0,0,1-1.406.563,1.987,1.987,0,0,1-1.43-.562A1.869,1.869,0,0,1,2-9.984a1.945,1.945,0,0,1,.586-1.43A1.945,1.945,0,0,1,4.016-12Z" transform="translate(-2 12)" fill="#fff"/>
          </svg>
        </a>
      <!-- X link -->
      <a href="https://x.com/OfferX_sa" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17">
          <path id="ic_x"
            d="M483.984,356.79l4.073,5.495,2.877,3.882-6.993,7.624h1.574l6.123-6.675,4.947,6.675h5.356l-7.341-9.9,6.509-7.1h-1.574l-5.639,6.147-4.556-6.147Zm2.315,1.17h2.461l10.866,14.66h-2.461Z"
            transform="translate(-483.94 -356.79)" fill="#fff" />
        </svg>
      </a>
      <!-- Instegram link -->
      <a href="https://www.instagram.com/offer_x_sa" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path id="ic_instagram"
            d="M7.8,3.225H15.4a5.194,5.194,0,0,1,5.2,5.2v7.6a5.194,5.194,0,0,1-5.2,5.2H7.8a5.194,5.194,0,0,1-5.2-5.2v-7.6a5.194,5.194,0,0,1,5.2-5.2ZM7.627,5a3.246,3.246,0,0,0-3.253,3.25V16.2a3.246,3.246,0,0,0,3.253,3.253h7.943A3.246,3.246,0,0,0,18.824,16.2V8.252A3.246,3.246,0,0,0,15.572,5Zm8.7,1.35a1.228,1.228,0,0,1,.782.36,1.1,1.1,0,1,1-1.563,0,1.228,1.228,0,0,1,.782-.36ZM11.6,7.7a4.544,4.544,0,1,1-3.19,1.332A4.482,4.482,0,0,1,11.6,7.7Zm0,1.817a2.731,2.731,0,0,0-1.9.8,2.579,2.579,0,0,0-.761,1.9A2.6,2.6,0,0,0,11.6,14.886a2.6,2.6,0,0,0,2.662-2.662,2.579,2.579,0,0,0-.76-1.9,2.731,2.731,0,0,0-1.9-.8Z"
            transform="translate(-2.6 -3.225)" fill="#fff" />
        </svg>

      </a>
        <!-- Facebook link -->
        <!-- <a href="https://" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18">
            <path id="ic_facebook" d="M16.775,3.225V6.816H14.967a.8.8,0,0,0-.673.444,1.928,1.928,0,0,0-.21.908v2.239h2.692v3.634H14.084v7.183H10.467V14.042H7.775V10.408h2.692V6.816a3.6,3.6,0,0,1,3.617-3.592Z" transform="translate(-7.775 -3.225)" fill="#fff"/>
          </svg>          
        </a> -->
    </div>

    <p class="col-md-6 col-12 d-flex align-items-center justify-content-center">
      © {{"general.All rights reserved"|translate}} 2024
    </p>

  </div>
</footer>